import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // フォロー中のユーザー一覧
    follows: [],
    // フォローされているユーザー一覧
    followers: []
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} フォロー中の情報
   */
  follows: state => state.follows,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid 確認したいユーザーのユーザーID
   * @return {Object} フォロー中（友達登録中）の情報
   */
  follow: state => uid => state.follows.some(follow => follow.follow === uid) ? state.follows.filter(follow => follow.follow === uid)[0] : null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} フォロワーの情報
   */
  followers: state => state.followers,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} レコメンド対象のフォロワーの情報
   */
  recommendFollowerUIDs: state => {
    const followUids = state.follows.map(follow => follow.follow)
    return state.followers.map(follower => follower.uid).filter(followerUID => !followUids.includes(followerUID))
  }
}

const mutations = {
  /**
   * ユーザー情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} follow フォロー中の情報
   */
  setFollow: (state, follow) => {
    state.follows.push(follow)
  },
  /**
   * フォロワー情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} followers フォロー中の情報
   */
  setFollowers: (state, followers) => {
    state.followers.push(followers)
  },
  /**
   * ユーザー情報をstateにアンセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} fid followsのドキュメントID
   */
  unsetFollow: (state, fid) => {
    state.follows = state.follows.filter(follow => follow.fid !== fid)
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 全フォロー（友達）情報の取得
   * @param {String} uid ユーザーID
   */
  getFollows: async ({ commit }, uid) => {
    try {
      const snapshot = await firestore
        .collection('follows')
        .where('uid', '==', uid)
        .orderBy('createdAt', 'desc')
        .get()

      snapshot.forEach(doc => {
        commit('setFollow', Object.assign(doc.data(), { fid: doc.id }))
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 指定したuidのユーザのフォロワーを取得する
   * @param {String} uid ユーザーID
   */
  getFollowers: async ({ commit }, uid) => {
    try {
      const snapshot = await firestore
        .collection('follows')
        .where('follow', '==', uid)
        .get()

      snapshot.forEach(doc => {
        commit('setFollowers', Object.assign(doc.data(), { fid: doc.id }))
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * フォロー（友達）情報の追加
   * @param {Object} payload 引数
   * @param {String} payload.uid 自分のユーザーID
   * @param {String} payload.follow フォロー（友達登録）するユーザーID
   * @param {Date} payload.createdAt 作成日
   */
  addFollow: async ({ commit }, payload) => {
    try {
      const doc = await firestore
        .collection('follows')
        .add(payload)

      commit('setFollow', Object.assign(payload, { fid: doc.id }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * フォロー（友達）情報の削除
   * @param {String} fid followsのドキュメントID
   */
  deleteFollow: async ({ commit }, fid) => {
    try {
      await firestore
        .collection('follows')
        .doc(fid)
        .delete()
      commit('unsetFollow', fid)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
