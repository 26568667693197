import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 取得済みかどうか
    isGot: false,
    // 最新の評価（脳タイプ診断のみ）
    review: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 取得済みかどうか
   */
  isGot: state => state.isGot,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 最新の評価結果
   */
  review: state => state.review
}

const mutations = {
  /**
   * 評価取得フラグの格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} isGot 取得済みかどうか
   */
  setIsGot: (state, isGot) => {
    state.isGot = isGot
  },
  /**
   * 評価結果の格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} review 評価
   */
  setReview: (state, review) => {
    state.review = review
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 評価の追加
   * @param {Object} review 評価
   */
  addReview: async ({ commit }, review) => {
    try {
      await firestore
        .collection('reviews')
        .add(review)

      commit('setReview', review)
      commit('setIsGot', true)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 最新の評価を取得
   * @param {String} uid ユーザーID
   */
  getReview: async ({ commit }, uid) => {
    try {
      const snapshot = await firestore
        .collection('reviews')
        .where('uid', '==', uid)
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()

      snapshot.forEach(doc => {
        commit('setReview', doc.data())
      })
      commit('setIsGot', true)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
