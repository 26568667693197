<template>
  <header class="header" v-if="isDisplay">
    <v-btn variant="text" class="header__back" v-if="type.includes('back')" @click="back()">
      <v-icon class="header__back__icon">mdi-arrow-left</v-icon>
    </v-btn>
    <v-img v-if="type.includes('logo')" class="header__logo" @click="$router.push({ name: 'home' })"
           :src="require('@/assets/img/logo.svg')" />
    <p class="header__title" v-if="type.includes('title') || type.includes('dynamic_title')">{{ title }}</p>
    <v-btn v-if="type.includes('menu')" class="header__menu"
           @click="pageName === 'menu' ? back() : $router.push({ name: 'menu' })">
      <v-icon class="header__menu__icon">{{ pageName === 'menu' ? 'mdi-close' : 'mdi-menu' }}</v-icon>
    </v-btn>
  </header>
</template>

<script>
// ヘッダーを表示させないページ名
const NOT_DISPLAY = [
  'line',
  'error',
  'notfound',
  'noexists',
  'maintenance',
  'notsupported'
]

// ヘッダーの詳細
// type: back, logo, title
const HEADER_DETAIL = {
  top: {
    type: ['logo', 'menu']
  },
  love: {
    type: ['logo', 'menu']
  },
  recruit: {
    type: ['back', 'logo', 'menu']
  },
  login: {
    type: ['back', 'logo', 'menu']
  },
  signup_auth: {
    type: ['back', 'logo', 'menu']
  },
  signup_detail: {
    type: ['logo', 'menu']
  },
  signup_sended: {
    type: ['logo', 'menu']
  },
  forgot: {
    type: ['back', 'logo', 'menu']
  },
  forgot_sended: {
    type: ['back', 'logo', 'menu']
  },
  forgot_done: {
    type: ['logo', 'menu']
  },
  mail_action: {
    type: ['back', 'logo', 'menu']
  },
  home: {
    type: ['logo', 'menu']
  },
  check: {
    type: ['back', 'logo', 'menu']
  },
  check_result: {
    type: ['back', 'logo', 'menu']
  },
  questions: {
    type: ['back', 'logo', 'menu']
  },
  questions_result: {
    type: ['back', 'logo', 'menu']
  },
  account: {
    type: ['back', 'logo', 'menu']
  },
  account_setting: {
    type: ['back', 'logo', 'menu']
  },
  profile: {
    type: ['back', 'logo', 'menu']
  },
  profile_ogp: {
    type: ['back', 'logo', 'menu']
  },
  profile_code: {
    type: ['back', 'logo', 'menu']
  },
  friends: {
    type: ['back', 'logo', 'menu']
  },
  friends_recommend: {
    type: ['back', 'logo', 'menu']
  },
  friends_none: {
    type: ['back', 'logo', 'menu']
  },
  cancel: {
    type: ['back', 'logo', 'menu']
  },
  cancel_auth: {
    type: ['back', 'logo', 'menu']
  },
  cancel_done: {
    type: ['logo', 'menu']
  },
  book: {
    type: ['back', 'logo', 'menu']
  },
  book_about: {
    type: ['back', 'logo', 'menu']
  },
  book_desire: {
    type: ['back', 'logo', 'menu']
  },
  book_compare: {
    type: ['back', 'logo', 'menu']
  },
  book_mbti: {
    type: ['back', 'logo', 'menu']
  },
  menu: {
    type: ['logo', 'menu']
  },
  policy: {
    type: ['back', 'logo', 'menu']
  },
  terms: {
    type: ['back', 'logo', 'menu']
  }
}

export default {
  computed: {
    /**
     * @return {Boolean} ヘッダーを表示させるかどうか
     */
    isDisplay () {
      return !NOT_DISPLAY.includes(this.$route.name)
    },
    /**
     * @return {String} router.jsで定義された名前
     */
    pageName () {
      return this.$route.name
    },
    /**
     * @return {String} ヘッダーのタイトル
     */
    title () {
      return this.type.includes('title') ? HEADER_DETAIL[this.pageName].title :
        this.type.includes('dynamic_title') ? this.$store.getters.dynamicTitle :
          ''
    },
    /**
     * @return {String} ヘッダーのタイプ
     */
    type () {
      return this.pageName && HEADER_DETAIL[this.pageName].type ? HEADER_DETAIL[this.pageName].type : ''
    }
  },
  methods: {
    /**
     * 前のページに戻る
     * 直リンクなどで前のページが存在しない場合はホームへ遷移させる
     */
    back () {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'home' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.header {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: $header_zindex;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  max-width: $max_width;
  height: $header_height;
  text-align: center;
  background-color: $yellow_pale_color;
  transform: translate(-50%, 0);
  &__back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: $padding_width;
    min-width: auto;
    padding: 0;
    margin: auto;
    color: $red_normal_color;
    &__icon {
      &.v-icon {
        font-size: 3rem;
      }
    }
  }
  &__logo {
    width: 100%;
    max-width: 110px;
    height: $unit_size*2;
    max-height: calc(#{$header_height} - 10px);
    margin: $unit_size*2 auto;
  }
  &__title {
    margin: 0;
    font-size: 1.2rem;
    color: $red_normal_color;
  }
  &__menu {
    position: absolute;
    top: 0;
    right: $padding_width;
    bottom: 0;
    min-width: auto;
    padding: 0;
    margin: auto;
    color: $red_normal_color;
    background-color: transparent;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
    &__icon {
      &.v-icon {
        font-size: 3.6rem;
      }
    }
  }
}
</style>
