import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 友達かも情報を取得済みかどうか
    isGot: false,
    // 友達かも情報
    recommendFollow: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 取得済みかどうか
   */
  isGot: state => state.isGot,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 友達かも情報
   */
  recommendFollow: state => state.recommendFollow
}

const mutations = {
  /**
   * 取得フラグの格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} isGot 取得済みかどうか
   */
  setIsGot: (state, isGot) => {
    state.isGot = isGot
  },
  /**
   * 友達かも情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} recommendFollow 友達かも情報
   */
  setRecommendFollow: (state, recommendFollow) => {
    state.recommendFollow = recommendFollow
  },
  /**
   * 非表示にするユーザーIDの一覧を更新
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String[]} newExcludeUIDs 更新する非表示にするユーザーIDの一覧
   */
  setExcludeUIDs: (state, newExcludeUIDs) => {
    const newRecommendFollow = state.recommendFollow
    newRecommendFollow.excludeUids = newExcludeUIDs
    state.recommendFollow = Object.assign({}, newRecommendFollow)
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 全友達かも情報の取得
   * @param {String} uid ユーザーID
   */
  getRecommendFollow: async ({ commit }, uid) => {
    try {
      const doc = await firestore
        .collection('recommend_follows')
        .doc(uid)
        .get()

      if (doc.exists) commit('setRecommendFollow', Object.assign(doc.data(), { uid: doc.id }))
      commit('setIsGot', true)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 友達かもから非表示にする
   * @param {String} authUID ログイン中のユーザーID
   * @param {String} unsetUID 非表示にするユーザーID
   */
  unsetRecommend: async ({ commit, state }, { authUID, unsetUID }) => {
    const excludeUIDs = Array.from(new Set([...state.recommendFollow.excludeUids, ...[unsetUID]]))
    await firestore
      .collection('recommend_follows')
      .doc(authUID)
      .set({
        excludeUids: excludeUIDs,
        updatedAt: new Date()
      }, { merge: true })

    commit('setExcludeUIDs', excludeUIDs)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
