import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 自分の回答履歴
    // { rid: { qid: {}, qid: {}, ... }, ... }
    answers: {},
    // 性別情報
    sex: null,
    // 未登録ユーザの回答履歴
    // { rid: { qid: {}, qid: {}, ... }, ... }
    nonUserAnswers: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} rid ドキュメントID
   * @return {Object} 指定診断結果に紐づく回答履歴
   */
  answers: state => rid => state.answers[rid] ? state.answers[rid] : null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} 性別情報
   */
  sex: state => state.sex,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} rid ドキュメントID
   * @return {Object} 未登録ユーザの指定診断結果に紐づく回答履歴
   */
  nonUserAnswers: state => rid => state.nonUserAnswers[rid] || null
}

const mutations = {
  /**
   * 回答履歴の格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} rid 診断結果のドキュメントID
   * @param {String} qid 回答のドキュメントID
   * @param {String} answer 回答
   */
  setAnswer: (state, { rid, qid, answer }) => {
    // 空オブジェクトの代入
    if (!state.answers[rid]) state.answers[rid] = {}

    // 回答履歴の格納
    state.answers[rid][qid] = answer
    state.answers = Object.assign({}, state.answers)
  },
  /**
   * 性別情報の格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} sex 性別情報
   */
  setSex: (state, sex) => {
    state.sex = sex
  },
  /**
   * 未登録ユーザの回答結果を保存する
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} answers 回答結果 { rid: { qid: {}, qid: {}, ... }, ... }
   */
  setNonUserAnswers: (state, answers) => {
    state.nonUserAnswers = answers
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 回答履歴の追加
   * @param {Object} payload 引数
   * @param {String} payload.rid 親コレクションのドキュメントID
   * @param {Object} payload.params 作成する値
   * @return {String} ドキュメントID
   */
  addAnswer: async ({ commit }, payload) => {
    try {
      const res = await firestore
        .collection('results')
        .doc(payload.rid)
        .collection('answers')
        .add(payload.params)

      return res.id
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 指定診断結果に紐づく回答履歴の取得
   * @param {String} rid 診断結果のドキュメントID
   */
  getAnswers: async ({ commit }, rid) => {
    try {
      const snapshot = await firestore
        .collection('results')
        .doc(rid)
        .collection('answers')
        .get()

      snapshot.forEach(doc => {
        const answer = Object.assign(doc.data(), { aid: doc.id })
        commit('setAnswer', { rid: rid, qid: answer.qid, answer: answer })
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 性別情報の取得
   * @param {String} rid 脳タイプ診断結果のドキュメントID
   */
  getSex: async ({ commit }, rid) => {
    const snapshot = await firestore
      .collection('results')
      .doc(rid)
      .collection('answers')
      .get()

    const doc = snapshot.docs.find(doc => ['男性', '女性'].includes(doc.data().answer))
    commit('setSex', doc.data().answer)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
