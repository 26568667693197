import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 質問の集計結果
    // { qid: {}, qid: {}, ... }
    questionSummaries: {},
    // 分析結果
    // { qid: [], qid: [], ... }
    detailResults: {},
    // 分析結果を表示する最小質問数
    minmunAnswerNum: 10
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} qid 質問ID
   * @return {Object} 質問の集計結果（ない場合はnull）
   */
  questionSummary: state => qid => state.questionSummaries[qid] || null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} qid 質問ID
   * @return {Object} 質問の分析結果（ない場合はnull）
   */
  detailResults: state => qid => state.detailResults[qid] || null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 分析結果を表示する最小質問数
   */
  minmunAnswerNum: state => state.minmunAnswerNum
}

const mutations = {
  /**
   * 集計結果情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} qid 質問ID
   * @param {Object} questionSummary 質問の集計結果情報
   */
  setQuestionSummary: (state, { qid, questionSummary }) => {
    state.questionSummaries[qid] = questionSummary
  },
  /**
   * 集計結果の詳細情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} qid 質問ID
   * @param {Object} detailResults 質問の集計結果の詳細情報
   */
  pushDetailResults: (state, { qid, detailResult }) => {
    state.detailResults = {
      ...state.detailResults,
      [qid]: [...(state.detailResults[qid] || []), detailResult]
    }
  },
  /**
   * 集計結果情報を更新
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} qid 質問ID
   * @param {Object} params 更新する値
   */
  updateQuestionSummary: (state, { qid, params }) => {
    Object.keys(params).forEach(key => {
      state.questionSummaries[qid][key] = params[key]
    })
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 集計結果情報の取得
   * @param {String} qid 質問ID
   * @return {void}
   */
  getQuestionSummary: async ({ commit }, qid) => {
    try {
      const snapshot = await firestore
        .collection('question_summaries')
        .where('qid', '==', qid)
        .get()

      // 集計結果の格納
      snapshot.forEach(doc => {
        const data = Object.assign(doc.data(), { qsid: doc.id })
        commit('setQuestionSummary', { qid: qid, questionSummary: data })
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 分析結果の取得
   * @param {String} qsid 集計結果ID
   * @param {String} qid 質問ID
   * @return {void}
   */
  getDetailResults: async ({ commit }, { qsid, qid }) => {
    try {
      const snapshot = await firestore
        .collection('question_summaries')
        .doc(qsid)
        .collection('details')
        .get()

      // 分析結果の格納
      snapshot.forEach(doc => {
        const data = Object.assign(doc.data(), { did: doc.id })
        commit('pushDetailResults', { qid: qid, detailResult: data })
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 集計結果情報の更新
   * @param {String} qsid 集計結果ID
   * @param {String} qid 質問ID
   * @param {Object} postParams
   * @return {void}
   */
  updateQuestionSummary: async ({ commit }, { qsid, qid, postParams }) => {
    try {
      await firestore
        .collection('question_summaries')
        .doc(qsid)
        .update(postParams)

      // 集計結果の格納
      commit('updateQuestionSummary', { qid: qid, params: postParams })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
