import { firestore, fieldValue } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 全ユーザーのオブジェクト一覧
    // { uid: {}, uid: {}, ... }
    users: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   * @return {Object} ユーザー情報
   */
  user: state => uid => state.users[uid] ? state.users[uid] : null
}

const mutations = {
  /**
   * ユーザー情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数
   * @param {String} payload.uid ユーザーID
   * @param {Object} payload.user ユーザーのオブジェクト
   */
  setUser: (state, payload) => {
    state.users = Object.assign({}, state.users, { [payload.uid]: payload.user })
  },
  /**
   * ユーザーIDに紐づくfirstnameを削除する
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   */
  deleteFirstName: (state, uid) => {
    delete state.users[uid].firstname
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * ユーザーの追加
   * @param {Object} payload 引数
   * @param {String} payload.uid ユーザーID
   * @param {String} payload.params ユーザー情報のオブジェクト（詳細はDB設計参照）
   */
  addUser: async ({ commit }, payload) => {
    try {
      await firestore
        .collection('users')
        .doc(payload.uid)
        .set(payload.params)

      commit('setUser', {
        uid: payload.uid,
        user: payload.params
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ユーザー情報を取得
   * @param {String} uid ユーザーID
   * @return {Object} ユーザー情報
   */
  getUser: async ({ commit }, uid) => {
    try {
      const doc = await firestore
        .collection('users')
        .doc(uid)
        .get()

      const user = doc.exists ? doc.data() : null
      if (user) commit('setUser', { uid: uid, user: user })

      return user
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ユーザー情報の更新
   * @param {Object} payload 引数の情報
   * @param {String} payload.uid ユーザーID
   * @param {Object} payload.params ユーザー情報
   */
  updateUser: async ({ commit }, payload) => {
    try {
      await firestore
        .collection('users')
        .doc(payload.uid)
        .update(payload.params)
      commit('setUser', { uid: payload.uid, user: payload.params })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ユーザー情報の削除
   * @param {String} uid ユーザーID
   */
  deleteUser: async ({ commit }, uid) => {
    try {
      await firestore
        .collection('users')
        .doc(uid)
        .update({
          firstname: 'ユーザー',
          lastname: '退会済み',
          isDeleted: true,
          updatedAt: new Date()
        })

      commit('setUser', { uid: uid, user: null })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ユーザーのfirstnameを削除
   * @param {String} uid ユーザーID
   */
  deleteFirstName: async ({ commit }, uid) => {
    try {
      await firestore
        .collection('users')
        .doc(uid)
        .update({
          firstname: fieldValue.delete()
        })
      commit('deleteFirstName', uid )
    } catch (error) {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
