import { firebase } from '@/firebase'

// functionsがあるリージョン
const REGION = 'asia-northeast1'

const getDefaultState = () => {
  return {
    // RSSの取得結果（note）
    rssNoteResponse: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的なstate
   * @return {Object} RSSの返却値
   */
  rssNoteResponse: state => state.rssNoteResponse
}

const mutations = {
  /**
   * noteのRSS情報の格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} response 追加するRSSの返却値
   */
  setRssNoteResponse: (state, response) => {
    state.rssNoteResponse = response
  }
}

const actions = {
  /**
   * LINE認証を行う
   * @param {String} code OIDC認証コード
   * @param {String} nonce 認証改竄防止用のID
   * @return {Object} カスタムトークンを含むJSONオブジェクト { token: <カスタムトークンの値>}
   */
  async authLine ({ commit }, { code, nonce }) {
    const authLine = firebase.functions(REGION).httpsCallable('authLine')
    const authLineResponse = await authLine({ code, nonce })
    return authLineResponse.data
  },
  /**
   * LINEユーザとのリンクを解除する
   */
  async unlinkLine ({ commit }) {
    const unlinkLine = firebase.functions(REGION).httpsCallable('unlinkLine')
    await unlinkLine()
  },
  /**
   * NoteのRSSFeed情報の取得
   */
  async fetchNoteRssFeed ({ commit }) {
    const url = 'https://note.com/braincheck/rss'
    try {
      const fetchRssFeedCallable = firebase.functions(REGION).httpsCallable('fetchRssFeedCallable')
      const response = await fetchRssFeedCallable({ url: url })
      // データの整形
      const feed = response.data.feed
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(feed, 'text/xml')
      const items = Array.from(xmlDoc.getElementsByTagName('item')).map(item => {
        return {
          title: item.getElementsByTagName('title')[0].textContent,
          link: item.getElementsByTagName('link')[0].textContent,
          thumbnail: item.getElementsByTagName('media:thumbnail')[0].textContent,
          description: item.getElementsByTagName('description')[0].textContent.replace(/<[^>]*>/g, '') // タグ情報を削除
        }
      })
      commit('setRssNoteResponse', items)
    } catch (e) {
      console.log(e)
    }
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
