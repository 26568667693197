import { createStore } from 'vuex'

import affinities from '@/store/modules/affinities'
import auth from '@/store/modules/auth'
import authPassword from '@/store/modules/authPassword'
import cloudstorage from './modules/cloudstorage'
import functions from './modules/functions'
import users from '@/store/modules/users'
import follows from '@/store/modules/follows'
import recommendFollows from '@/store/modules/recommendFollows'
import checks from '@/store/modules/checks'
import maslows from '@/store/modules/checks/maslows'
import brains from '@/store/modules/checks/brains'
import questions from '@/store/modules/questions'
import questionAnswers from '@/store/modules/questionAnswers'
import questionCategories from '@/store/modules/questionCategories'
import questionHistories from '@/store/modules/questionHistories'
import questionSummaries from '@/store/modules/questionSummaries'
import totalQuestions from '@/store/modules/totalQuestions'
import results from '@/store/modules/results'
import totalResults from '@/store/modules/totalResults'
import answers from '@/store/modules/results/answers'
import rate from '@/store/modules/rate'
import reviews from '@/store/modules/reviews'
import notification from '@/store/modules/notification'
import cancels from '@/store/modules/cancels'
import maintenances from './modules/maintenances'

const modules = { affinities, auth, authPassword, cloudstorage, functions, users, follows, recommendFollows, checks, maslows, brains, questions, questionAnswers, questionCategories, questionHistories, questionSummaries, totalQuestions, results, totalResults, answers, rate, reviews, notification, cancels, maintenances }

export default createStore({
  modules: modules,
  state: {
    isFristAccess: true,
    isAuthProcessing: true,
    isSupportBrowser: false,
    isProcessing: true,
    telop: {
      show: false,
      msg: '',
      type: ''
    },
    redirectPath: null,
    queryParameter: '',
    dynamicTitle: '',
    // 最大の文字数
    MAX_LENGTH: {
      review: {
        opinion: 500,
        comment: 500
      }
    },
    background: {
      hasImage: true,
      color: 'clear_white'
    },
    recommendArticles: [
      { title: '脳タイプ診断、45万診断を超える性格診断サービスとは？たった18問であなたの性格が丸裸！？', description: '脳タイプ診断は、身体的特徴から判定する先天的脳と世の中をどのような抽象度で見ているかで判定する後天的脳の組み合わせによる性格診断プログラムです。', thumbnail: 'https://assets.st-note.com/production/uploads/images/131052665/rectangle_large_type_2_0c0e0e576549abe19386175636bae649.jpeg', link: 'https://note.com/braincheck/n/n1470fd37dd9c' },
      { title: '右脳派・左脳派で異なる社会の見え方。脳タイプが変われば、同じ出来事でも見え方は変わる。', description: 'どうしてそんなに自己中なの？実は本人は自己中な行動と思っていないことも….この記事では、脳タイプによって物事の捉え方が異なること、そして、それにより起きる様々なトラブルについて、この記事では解説していきます。', thumbnail: 'https://assets.st-note.com/production/uploads/images/131684448/rectangle_large_type_2_0f9b2eb1c7d1b47de7b8bbe43bcf2b2f.png?width=800', link: 'https://note.com/braincheck/n/n7cf50ec43c2a' },
      { title: '「MBTI診断」と「脳タイプ診断」の特徴と違いとは？', description: '今回は、リーダーシップ開発やチームビルディングなど、ビジネス現場でも活用されている「MBTI診断」の特徴を紹介し、「脳タイプ診断」との違いについて説明します。脳タイプ診断の脳タイプと、MBTI診断の性格タイプとの類似点を最後にマッチング表にまとめてあるので、ぜひご活用ください', thumbnail: 'https://mbdg.me/img/b5c56882eff019a4.jpg', link: `${window.location.protocol}//${window.location.host}/book/mbti` }
    ]
  },
  getters: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} 最初のアクセスかどうか
     */
    isFristAccess: state => state.isFristAccess,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} Authの処理中かどうか
     */
    isAuthProcessing: state => state.isAuthProcessing,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} 処理中かどうか
     */
    isProcessing: state => state.isProcessing,
    /**
     * UserAgentがWebView(LINE/Messanger/Yahooアプリ内ブラウザ)でないかの判定結果
     *
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Boolean} 動作対象のブラウザであること
     */
    isSupportBrowser: state => state.isSupportBrowser,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String} 遷移先のURL
     */
    redirectPath: state => state.redirectPath,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {String} 遷移先のURLのパラメータ
     */
    queryParameter: state => state.queryParameter,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object} テロップの情報
     */
    telop: state => state.telop,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object} 動的タイトル
     */
    dynamicTitle: state => state.dynamicTitle,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object} 最大文字数
     */
    MAX_LENGTH: state => state.MAX_LENGTH,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object} 画面の背景画像有無、色
     */
    background: state => state.background,
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @return {Object[]} おすすめの記事一覧
     */
    recommendArticles: state => state.recommendArticles
  },
  mutations: {
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isAuthProcessing Auth処理中かどうか
     */
    setAuthProcessing: (state, isAuthProcessing) => {
      state.isAuthProcessing = isAuthProcessing
      if (!isAuthProcessing && state.isFristAccess) state.isFristAccess = false
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isProcessing 処理中かどうか
     */
    setProcessing: (state, isProcessing) => {
      state.isProcessing = isProcessing
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Boolean} isSupportBrowser 動作対象のブラウザか
     */
    setSupportBrowser: (state, isSupportBrowser) => {
      state.isSupportBrowser = isSupportBrowser
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} path アクセスされたURL
     */
    setRedirectURL: (state, path) => {
      state.redirectPath = path
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} queryParameter アクセスされたURLのパラメータ
     */
    setQueryParameter: (state, queryParameter) => {
      state.queryParameter = queryParameter
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Object} telop テロップの情報
     * @param {Boolean} telop.show テロップ表示の有無
     * @param {String} telop.msg テロップの内容
     * @param {String} telop.type テロップの色 success, warning, error
     */
    setTelop: (state, telop) => {
      state.telop = telop
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {String} title タイトルの名前
     */
    setDynamicTitle: (state, title) => {
      state.dynamicTitle = title
    },
    /**
     * @param {Object} state 暗黙的に受け取るstate
     * @param {Object} background 画面の背景画像有無、色
     */
    setBackground: (state, background) => {
      state.background = background
    }
  },
  actions: {
    /**
     * 全モジュールのstoreをリセットする
     */
    resetState: ({ commit }) => {
      Object.keys(modules).forEach(key => {
        if (Object.keys(modules[key].state).length !== 0) commit(key + '/resetState')
      })
    }
  }
})
