<template>
  <footer class="footer" v-if="isDisplay">
    <p class="footer__mail">
      <a class="footer__mail__link" target="_blank" rel="noopener noreferrer"
         href="https://forms.gle/TsdpmHeVPPQAos1u7">
        <v-icon class="footer__mail__link__icon">mdi-email-outline</v-icon>
        お問い合わせ
      </a>
    </p>
    <p class="footer__policy">
      <router-link class="footer__policy__link" :to="{ name: 'terms' }">利用規約</router-link>&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;
      <router-link class="footer__policy__link" :to="{ name: 'policy' }">プライバシーポリシー</router-link>
    </p>
    <p class="footer__copylight">
      <v-icon class="footer__copylight__logo">mdi-copyright</v-icon>
      <span class="footer__copylight__text">{{nowYear}}</span>&nbsp;
      <a class="footer__copylight__text--link" href="http://illustrationlibrary.com/" target="_blank" rel="noopener noreferrer">秋山孝</a>&nbsp;
      <a class="footer__copylight__text--link" href="https://mikle.co.jp/" target="_blank" rel="noopener noreferrer">Mikle</a>
    </p>
  </footer>
</template>

<script>
// フッターを表示させないページ名
const NOT_DISPLAY = [
  'home',
  'line'
]

export default {
  computed: {
    /**
     * @return {Boolean} フッターを表示させるかどうか
     */
    isDisplay () {
      return !NOT_DISPLAY.includes(this.$route.name)
    },
    /**
     * @return {Number} 表示時の西暦
     */
    nowYear () {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';
@import '@/assets/sass/fontfamily.scss';

.footer {
  position: relative;
  left: 50%;
  z-index: $footer_zindex;
  display: flex;
  flex-direction: column;
  gap: $unit_size * 2;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: $max_width;
  padding: ($unit_size * 3) 0;
  margin-top: $unit_size*8;
  background-color: $pink_normal_color;
  transform: translate(-50%, 0);
  &__mail {
    display: flex;
    flex-direction: row;
    gap: $unit_size;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    font-family: $notosans;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    &__link {
      line-height: 1.6rem;
      color: $white_normal_color;
      text-align: center;
      letter-spacing: 0.05em;
      .v-icon {
        width: 20px;
        height: 20px;
        color: $white_normal_color;
      }
    }
  }
  &__policy {
    margin: 0;
    font-family: $notosans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4rem;
    color: $white_normal_color;
    text-align: center;
    letter-spacing: 0.05em;
    &__link {
      color: $white_normal_color;
    }
  }
  &__copylight {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-family: $notosans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4rem;
    color: $white_normal_color;
    text-align: center;
    letter-spacing: 0.05em;
    &__text {
      color: $white_normal_color;
      &--link {
        @extend .footer__copylight__text;
        text-decoration: underline;
      }
    }
    &__logo {
      margin-right: $unit_size;
      &.v-icon {
        font-size: 1.2rem;
        color: $white_normal_color;
      }
    }
  }
}
</style>
