import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 相性の一覧
    // { <相性名>: { isDifferentSex: boolean, affinityRates: {<先天的脳タイプ名1先天的脳タイプ名2>: rate, <>: rate, ... } }, ...}
    affinities: {},
    // 相性毎の相性率が高いユーザー一覧
    // { <相性名>: [{uid: rate}, {uid: rate}, ...], <相性名>: [...], ... }
    affinitiesUsers: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的なstate
   * @param {String} affinityName 相性名
   * @return {Object} 相性名に合致した相性のオブジェクト
   */
  affinity: state => affinityName => state.affinities[affinityName],
  /**
   * @param {Object} state 暗黙的なstate
   * @return {String[]} 相性名一覧
   */
  affinityNames: state => state.affinities ? Object.keys(state.affinities) : [],
  /**
   * @param {Object} state 暗黙的なstate
   * @return {String[]} 表示するユーザーが存在している相性名一覧
   */
  displayedAffinityNames: state => Object.keys(state.affinitiesUsers).filter(affinityUser => affinityUser.length > 0),
  /**
   * @param {Object} state 暗黙的なstate
   * @param {String} affinityName 相性名
   * @return {Object} 相性名に合致した相性のオブジェクト
   */
  affinityUsers: state => affinityName => state.affinitiesUsers[affinityName]
}

const mutations = {
  /**
   * 相性を追加する
   *
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} affinity 相性
   */
  setAffinity: (state, affinity) => {
    state.affinities = Object.assign({}, state.affinities, affinity)
  },
  /**
   * 相性毎の相性率が高いユーザー一覧を追加する
   *
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} affinitiesUsers 相性毎の相性率が高いユーザー一覧
   */
  setAffinitiesUsers: (state, affinitiesUsers) => {
    state.affinitiesUsers = Object.assign({}, {}, affinitiesUsers)
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 表示対象の相性の一覧を取得しstoreを更新する
   */
  async getAffinities ({ commit }) {
    try {
      const snapshot = await firestore
        .collection('affinities')
        .where('isDeleted', '==', false)
        .get()
      snapshot.forEach(doc => {
        const docData = doc.data()
        const affinityRate = {}
        docData.affinityRates.forEach(rate => {
          const keyName = rate.myInnate + rate.partnerInnate
          affinityRate[keyName] = rate.rate
        })
        commit('setAffinity', { [docData.name]: { isDifferentSex: docData.isDifferentSex, affinityRate } })
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 相性毎の相性率が高いユーザー一覧を取得しstoreを更新する
   *
   * @param {Object} loginUser { uid, sex, innate }
   * @param {Object[]} friends 友達一覧 [{ uid, sex, innate, matchedAnswersCount }, ...]
   */
  async getAffinitiesUsers ({ commit, dispatch }, { loginUser, friends }) {
    const affinitiesUsers = {}
    await Promise.all(friends.map(async friend => {
      const userAffinity = await dispatch('getUserAffinity', { loginUser, targetUser: friend })
      userAffinity.forEach(affinity => {
        if (affinitiesUsers[affinity.affinityName]) {
          affinitiesUsers[affinity.affinityName].push({ uid: affinity.uid, rate: affinity.rate })
        } else {
          affinitiesUsers[affinity.affinityName] = [{ uid: affinity.uid, rate: affinity.rate }]
        }
      })
    }))

    // 相性毎に相性率が高い順に友達を並び替えてstoreを更新する
    Object.keys(affinitiesUsers).forEach(affinityName => {
      const affinityUsers = affinitiesUsers[affinityName]
      affinityUsers.sort((user, nextUser) => nextUser.rate - user.rate)
      affinitiesUsers[affinityName] = affinityUsers
    })
    commit('setAffinitiesUsers', affinitiesUsers)
  },
  /**
   * 指定されたユーザーとの相性情報を取得する
   * @param {Object} payload { loginUser: {uid, sex innate}, targetUser: {uid, sex, innate, matchedAnswersCount}}
   * @return {Object} ユーザーの相性情報 [{ affinityName, uid, rate }, ...]
   */
  getUserAffinity ({ getters }, payload) {
    const affinitiesUsers = []
    const loginUser = payload.loginUser
    const targetUser = payload.targetUser

    // 相性率を求める際の係数
    const coefficient = 0.78 + (targetUser.matchedAnswersCount * 0.06)

    getters.affinityNames.forEach(affinityName => {
      const { isDifferentSex, affinityRate } = getters.affinity(affinityName)
      const isCalculatedRate = (!isDifferentSex || (isDifferentSex && loginUser.sex !== targetUser.sex))
      if (isCalculatedRate) {
        // 異性相性の場合はキー名が'<男性の先天的脳タイプ名><女性の先天的脳タイプ名>'を指定する
        const keyName = !isDifferentSex || (isDifferentSex && loginUser.sex === '男') ?
          loginUser.innate + targetUser.innate : targetUser.innate + loginUser.innate
        const rate = affinityRate[keyName] * coefficient

        affinitiesUsers.push({ affinityName, uid: targetUser.uid, rate })
      }
    })
    return affinitiesUsers
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
