import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 脳タイプごとの割合
    // { innate: { acquire: {}, acquire: {}, ... }, innate: { acquire: {}, acquire: {}, ... }, ... }
    rates: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} innate 先天的脳タイプ名
   * @param {String} acquire 後天的脳タイプ名
   * @return {Object} 脳タイプごとの割合
   */
  rate: state => (innate, acquire) => state.rates[innate][acquire]
}

const mutations = {
  /**
   * 脳タイプごとの割合をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} innate 先天的脳タイプ名
   * @param {String} acquire 後天的脳タイプ名
   * @param {Object} rate 割合
   */
  setRate: (state, { innate, acquire, rate }) => {
    if (!state.rates[innate]) state.rates[innate] = {}
    state.rates[innate][acquire] = rate
  },
  /**
   * stateのリセットを行う
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 脳タイプごとの割合の取得
   * @param {String} innate 先天的脳タイプ名
   * @param {String} acquire 後天的脳タイプ名
   */
  getRate: async ({ commit }, { innate, acquire }) => {
    try {
      const snapshot = await firestore
        .collection('rate')
        .where('innate', '==', innate)
        .where('acquire', '==', acquire)
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()

      snapshot.forEach(doc => {
        commit('setRate', { innate: innate, acquire: acquire, rate: Object.assign(doc.data(), { raid: doc.id }) })
      })
    } catch {
      router.push({ name: 'error' })
    }
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
