import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 回答履歴を取得済みか
    isGot: false,
    // 質問回答履歴
    history: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 回答履歴を取得済みか
   */
  isGot: state => state.isGot,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Objcet} 質問回答履歴（ない場合はnull）
   */
  history: state => state.history,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Number} 質問回答数
   */
  answerNum: state => state.history ? state.history.qids.length : 0
}

const mutations = {
  /**
   * 質問回答履歴の取得有無をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Boolean} isGot 質問履歴を取得済みか
   */
  setIsGot: (state, isGot) => {
    state.isGot = isGot
  },
  /**
   * 回答履歴をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} history 質問回答の履歴
   */
  setHistory: (state, history) => {
    state.history = history
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 回答履歴の更新（ない場合は作成）
   * @param {String} uid ユーザーID
   * @param {Object} postParams ポストパラメータ
   * @return {void}
   */
  setQuestionHistories: async ({ commit }, { uid, postParams }) => {
    try {
      await firestore
        .collection('question_histories')
        .doc(uid)
        .set(postParams, { merge: true })

      // 質問回答数の格納
      commit('setHistory', postParams)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 質問に関する履歴情報の取得
   * @param {String} uid ユーザーID
   * @return {void}
   */
  getQuestionHistories: async ({ commit }, uid) => {
    try {
      const doc = await firestore
        .collection('question_histories')
        .doc(uid)
        .get()

      // 質問回答履歴の格納
      commit('setHistory', doc.exists ? doc.data() : null)
      commit('setIsGot', true)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
