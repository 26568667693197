import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 質問総数を取得済みか
    isGot: false,
    // 質問総数
    total: 0
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 質問総数を取得済みか
   */
  isGot: state => state.isGot,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Number} 質問総数
   */
  total: state => state.total
}

const mutations = {
  /**
   * 質問総数の取得有無をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Boolean} isGot 質問総数を取得済みか
   */
  setIsGot: (state, isGot) => {
    state.isGot = isGot
  },
  /**
   * 質問総数をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Number} total 質問総数
   */
  setTotal: (state, total) => {
    state.total = total
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 質問総数の取得
   * @return {void}
   */
  getQuestionNum: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('total_questions')
        .get()

      // 質問総数の格納
      snapshot.forEach(doc => {
        commit('setTotal', doc.data().count)
      })
      commit('setIsGot', true)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
