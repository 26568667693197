import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 診断結果の情報
    // { uid: [], uid: [], ... }
    results: {},
    // ユーザー未登録の診断結果情報
    nonUserResult: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   * @return {Object[]} 指定ユーザーの診断結果の一覧
   */
  results: state => uid => state.results[uid] || [],
  /**
   * @param {Object} state 暗黙的に受け取るstat
   * @param {String} uid ユーザーID
   * @param {String} cid 診断のドキュメントID
   * @return {Object} 指定ユーザー/診断の診断結果
   */
  result: state => (uid, cid) => state.results[uid] ? state.results[uid].find(result => result.cid === cid) : null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} ユーザー情報が紐づいてない診断結果
   */
  nonUserResult: state => state.nonUserResult
}

const mutations = {
  /**
   * uidに紐づく全ての診断結果をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数
   * @param {Object} payload.uid ユーザーID
   * @param {Object} payload.results 診断結果
   */
  setResults: (state, payload) => {
    state.results = Object.assign({}, state.results, { [payload.uid]: payload.results })
  },
  /**
   * 診断結果を追加
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   * @param {String} rid 診断結果のドキュメントID
   * @param {Object} result 診断結果
   */
  setResult: (state, { uid, rid, result }) => {
    // もし存在する場合は削除してから追加する
    const results = state.results[uid] ? state.results[uid].filter(elem => elem.rid !== rid) : []
    results.push(result)
    state.results = Object.assign({}, state.results, { [uid]: results })
  },
  /**
   * ユーザー未登録の診断結果をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} result 診断結果
   */
  setNonUserResult: (state, result) => {
    state.nonUserResult = result
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 診断結果の作英
   * @param {Object} params 作成する値
   * @return {String} ドキュメントID
   */
  addResult: async ({ commit }, params) => {
    try {
      const res = await firestore
        .collection('results')
        .add(params)

      const rid = res.id
      if (!params.uid) commit('setNonUserResult', Object.assign(params, { rid: rid }))
      if (params.uid) commit('setResult', { uid: params.uid, rid: rid, result: Object.assign(params, { rid: rid }) })

      return rid
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 診断結果の取得
   * @param {String} rid 診断結果のドキュメントID
   */
  getResult: async ({ commit }, rid) => {
    try {
      const doc = await firestore
        .collection('results')
        .doc(rid)
        .get()

      if (doc.exists && !doc.data().uid) commit('setNonUserResult', Object.assign(doc.data(), { rid: doc.id }))
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 指定したuidに紐づく全診断結果の取得
   * @param {String} uid ユーザーID
   * @return {Object[]} 全診断結果
   */
  getResults: async ({ commit }, uid) => {
    try {
      const snapshot = await firestore
        .collection('results')
        .where('uid', '==', uid)
        .get()

      const results = []
      snapshot.forEach(doc => {
        results.push(Object.assign(doc.data(), { rid: doc.id }))
      })
      if (results.length > 0) commit('setResults', { uid: uid, results: results })

      return results
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 指定した診断結果の情報を更新
   * @param {Object} payload 引数
   * @param {String} payload.rid 診断結果のドキュメントID
   */
  updateResult: async ({ commit }, payload) => {
    try {
      await firestore
        .collection('results')
        .doc(payload.rid)
        .update(payload.params)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 診断結果の削除
   * @param {String} rid 診断結果のドキュメントID
   */
  deleteResult: async ({ commit }, rid) => {
    try {
      await firestore
        .collection('results')
        .doc(rid)
        .delete()
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
