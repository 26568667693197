import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // カテゴリーを取得済みか
    isGot: false,
    // カテゴリーの一覧
    categories: [{ qcid: 'all', value: 'すべて' }]
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} カテゴリーを取得済みか
   */
  isGot: state => state.isGot,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} qcid カテゴリーID
   * @return {Objcet} カテゴリー情報
   */
  categoryNameByQCID: state => qcid => {
    const index = state.categories.findIndex(category => category.qcid === qcid)
    return state.categories[index] ? state.categories[index].value : null
  },
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Objcet} カテゴリーの名前一覧
   */
  categoryNames: state => state.categories.map(category => category.value)
}

const mutations = {
  /**
   * カテゴリーの取得有無をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Boolean} isGot カテゴリーを取得済みか
   */
  setIsGot: (state, isGot) => {
    state.isGot = isGot
  },
  /**
   * カテゴリーをstateを追加
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} category カテゴリー名
   */
  unshiftCategory: (state, category) => {
    state.categories.unshift(category)
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * カテゴリーの一覧を取得
   * @return {void}
   */
  getCategories: async ({ commit }) => {
    try {
      const sanapshot = await firestore
        .collection('question_categories')
        .orderBy('updatedAt', 'desc')
        .get()

      // カテゴリーの格納
      sanapshot.forEach(doc => commit('unshiftCategory', { qcid: doc.id, value: doc.data().name }))
      commit('setIsGot', true)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
