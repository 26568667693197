import { firebase } from '@/firebase'
import router from '@/router'

// authで利用するセッションストレージのkey名
const sessionStorageKey = {
  newRID: 'newRID',
  isSignupWithPassword: 'isSignupWithPassword',
  isSigninWithPassword: 'isSigninWithPassword',
  isResetPassword: 'isResetPassword',
  isDeleteAccountWithPassword: 'isDeleteAccountWithPassword',
  isManualLoggedin: 'isManualLoggedin',
  cancelUID: 'cancelUID',
  cancelReason: 'cancelReason'
}

const getDefaultState = () => {
  return {
    // メールアドレスが認証済みかどうか
    emailVerified: false,
    // パスワード認証ログインかどうか
    isPasswordProvider: false
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} メール認証済みかどうか
   */
  emailVerified: state => state.emailVerified,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} パスワード認証によるログインかどうか
   */
  isPasswordProvider: state => state.isPasswordProvider
}

const mutations = {
  /**
   * メール認証状況とパスワード認証かどうかの状態を更新する
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 更新後の値
   * @param {Object} payload.emailVerified メール認証状態
   * @param {Object} payload.isPasswordProvider パスワード認証によるログインかどうか
   */
  setEmailState: (state, payload) => {
    state.emailVerified = payload.emailVerified
    state.isPasswordProvider = payload.isPasswordProvider
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * メールアドレスとパスワードでユーザ登録を行う
   *
   * @param {String} email メールアドレス
   * @param {String} password パスワード
   */
  signupWithPassword: async ({ commit, dispatch }, { email, password }) => {
    try {
      // onAuthで何もさせない。
      sessionStorage.setItem(sessionStorageKey.isSignupWithPassword, true)
      const newRID = sessionStorage.getItem(sessionStorageKey.newRID)
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password)
      const user = userCredential.user
      await Promise.all([
        dispatch('results/updateResult', {
          rid: newRID,
          params: {
            uid: user.uid,
            updatedAt: new Date()
          }
        }, { root: true }),
        user.updateProfile({ displayName: '脳タイプ診断ユーザー' })
      ])
      await user.sendEmailVerification()
      commit('setEmailState', { emailVerified: false, isPasswordProvider: true })
      router.push({ name: 'signup_sended' })
    } catch (error) {
      sessionStorage.removeItem(sessionStorageKey.isSignupWithPassword)
      switch (error.code) {
      case 'auth/email-already-in-use':
        commit('setTelop', { show: true, msg: 'このメールアドレスは使用されています', type: 'warning' }, { root: true })
        commit('setProcessing', false, { root: true })
        break
      default:
        commit('setTelop', { show: true, msg: 'アカウント登録時に問題が発生しました\nしばらく時間をおいてお試しください', type: 'error' }, { root: true })
        router.replace({ name: 'error' })
      }
    } finally {
      sessionStorage.removeItem(sessionStorageKey.isSignupWithPassword, true)
      sessionStorage.removeItem(sessionStorageKey.newRID)
    }
  },
  /**
   * 登録メールアドレスに所持確認用のメールを再送する
   * @param {Object} user 再送対象のユーザ
   */
  reSendEmailVerification: async ({ commit }, user) => {
    try {
      if (!isVerifiedEmailUser(user)) {
        await user.sendEmailVerification()
      }
      return { name: 'signup_sended' }
    } catch (error) {
      switch (error.code) {
      case 'auth/too-many-requests':
        commit('setTelop', { show: true, msg: 'メールボックスを確認してください', type: 'error' }, { root: true })
        return { name: 'signup_sended' }
      default:
        commit('setTelop', { show: true, msg: '認証メール再送時に問題が発生しました\nしばらく時間をおいてお試しください', type: 'error' }, { root: true })
        return { name: 'error' }
      }
    }
  },
  /**
   * メールアドレスとパスワードでサインインする
   *
   * @param {String} email メールアドレス
   * @param {String} password パスワード
   */
  signInWithPassword: async ({ commit }, { email, password }) => {
    try {
      sessionStorage.setItem(sessionStorageKey.isManualLoggedin, true)
      await firebase.auth().signInWithEmailAndPassword(email, password)
    } catch (error) {
      sessionStorage.removeItem(sessionStorageKey.isManualLoggedin)
      // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithemailandpassword
      switch (error.code) {
      case 'auth/user-not-found':
      case 'auth/invalid-email':
      case 'auth/wrong-password':
        commit('setTelop', { show: true, msg: 'メールアドレスまたはパスワードが誤っています', type: 'warning' }, { root: true })
        commit('setProcessing', false, { root: true })
        break
      default:
        commit('setTelop', { show: true, msg: 'ログイン時に問題が発生しました\nしばらく時間をおいてお試しください', type: 'error' }, { root: true })
        router.replace({ name: 'error' })
      }
    }
  },
  /**
   * 指定したメールアドレス宛にパスワードリセットのためのメールを送信する
   *
   * @param {String} email メールアドレス
   */
  sendPasswordResetEmail: async ({ commit }, email) => {
    try {
      await firebase.auth().sendPasswordResetEmail(email)
      router.replace({ name: 'forgot_sended' })
    } catch (error) {
      switch (error.code) {
      case 'auth/invalid-email':
      case 'auth/user-not-found':
        commit('setTelop', { show: true, msg: 'メールアドレスが間違っています', type: 'warning' }, { root: true })
        commit('setProcessing', false, { root: true })
        break
      default:
        commit('setTelop', { show: true, msg: 'メール送信時に問題が発生しました\nしばらく時間をおいてお試しください', type: 'error' }, { root: true })
        router.replace({ name: 'error' })
      }
    }
  },
  /**
   * リセットするための実行コードが利用できるかを検証する
   * @param {String} actionCode リセットするための実行コード
   * @return {Boolean} 利用可否
   */
  verifyPasswordResetCode: async ({ commit }, actionCode) => {
    try {
      await firebase.auth().verifyPasswordResetCode(actionCode)
      return true
    } catch (error) {
      // @see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#verifypasswordresetcode
      switch (error.code) {
      case 'auth/expired-action-code':
        commit('setTelop', { show: true, msg: 'メールの有効期限が切れています\n再度メールの送信を行ってください', type: 'warning' }, { root: true })
        router.push({ name: 'forgot' })
        break
      case 'auth/invalid-action-code':
        commit('setTelop', { show: true, msg: '認証コードが不正かすでに利用済みです', type: 'warning' }, { root: true })
        router.push({ name: 'top' })
        break
      default:
        commit('setTelop', { show: true, msg: 'パスワードリセット中に問題が発生しました\nしばらく時間をおいてお試しください', type: 'error' }, { root: true })
        router.push({ name: 'error' })
      }
      return false
    }
  },
  /**
   * パスワードを再設定する
   *
   * @param {String} password 再設定するパスワード
   * @param {String} actionCode リセットするための実行用コード
   */
  resetPassword: async ({ commit, dispatch }, { password, actionCode }) => {
    try {
      await firebase.auth().confirmPasswordReset(actionCode, password)
      const user = firebase.auth().currentUser
      if (user) {
        // onAuthで何もさせない。
        sessionStorage.setItem(sessionStorageKey.isResetPassword, true)
        await dispatch('signoutFromPasswordAccount', { root: true })
      }
      router.push({ name: 'forgot_done' })
    } catch (error) {
      // @see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#confirmpasswordreset
      switch (error.code) {
      case 'auth/expired-action-code':
        commit('setTelop', { show: true, msg: 'メールの有効期限が切れています\n再度メールの送信を行ってください', type: 'warning' }, { root: true })
        router.push({ name: 'forgot' })
        break
      case 'auth/invalid-action-code':
        commit('setTelop', { show: true, msg: '認証コードが不正かすでに利用済みです', type: 'warning' }, { root: true })
        router.push({ name: 'top' })
        break
      default:
        commit('setTelop', { show: true, msg: 'パスワードリセット中に問題が発生しました\nしばらく時間をおいてお試しください', type: 'error' }, { root: true })
        router.push({ name: 'error' })
      }
    }
  },
  /**
   * emailアドレスの認証を行う
   * @param {String} actionCode 認証実行用コード
   */
  verifyEmail: async ({ commit }, actionCode) => {
    const user = firebase.auth().currentUser
    try {
      await firebase.auth().checkActionCode(actionCode)
      await firebase.auth().applyActionCode(actionCode)

      if (user) {
        commit('setEmailState', { emailVerified: true, isPasswordProvider: true })
        commit('setTelop', { show: true, msg: 'メールアドレスの認証が完了しました', type: 'success' }, { root: true })
        router.push({ name: 'signup_detail' })
      } else {
        commit('setTelop', { show: true, msg: 'メールアドレスの認証が完了しました\nログインしてお楽しみください', type: 'success' }, { root: true })
        router.push({ name: 'login' })
      }
    } catch (error) {
      // @see https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#applyactioncode
      switch (error.code) {
      case 'auth/expired-action-code':
        if (user) {
          const { name } = await dispatch('reSendEmailVerification', user)
          commit('setTelop', { show: true, msg: '有効期限が切れていたためメールを再送しました.', type: 'warning' }, { root: true })
          router.push({ name })
        } else {
          commit('setTelop', { show: true, msg: 'メールの有効期限が切れています\n再度ログインを行ってください', type: 'warning' }, { root: true })
          router.push({ name: 'login' })
        }
        break
      case 'auth/invalid-action-code':
        commit('setTelop', { show: true, msg: '認証コードが不正かすでに利用済みです', type: 'warning' }, { root: true })
        router.push({ name: 'top' })
        break
      default:
        commit('setTelop', { show: true, msg: 'パスワードリセット中に問題が発生しました\nしばらく時間をおいてお試しください', type: 'error' }, { root: true })
        router.push({ name: 'error' })
      }
    }
  },
  /**
   * サインアウトを実行する
   */
  signoutFromPasswordAccount: async ({ commit, dispatch }) => {
    try {
      await firebase.auth().signOut()

      // storeの不要な情報をリセットする
      dispatch('resetState', null, { root: true })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * アカウント削除
   */
  deleteAccount: async ({ commit, dispatch }, { email, password }) => {
    try {
      // onAuthで何もさせない。(deleteAuthのときに動く)
      sessionStorage.setItem(sessionStorageKey.isDeleteAccountWithPassword, true)
      const userCredencial = await firebase.auth().signInWithEmailAndPassword(email, password)
      const user = userCredencial.user
      const cancelUID = sessionStorage.getItem(sessionStorageKey.cancelUID)

      if (user.uid !== cancelUID) {
        // ログインしたアカウントが異なるため戻る
        commit('setTelop', { show: true, msg: 'ログインしているアカウントとキャンセル時のアカウントが異なります', type: 'warning' }, { root: true })
        await dispatch('auth/signout', null, { root: true })
        return
      }

      const cancelPromises = []
      cancelPromises.push(dispatch('users/deleteUser', user.uid, { root: true }))
      cancelPromises.push(dispatch('cancels/addCancel', { uid: user.uid, reason: sessionStorage.getItem(sessionStorageKey.cancelReason) }, { root: true }))

      await Promise.all(cancelPromises)
      await dispatch('auth/deleteAuth', null, { root: true })
      commit('setEmailState', { emailVerified: false, isPasswordProvider: false })

      sessionStorage.removeItem(sessionStorageKey.cancelUID)
      sessionStorage.removeItem(sessionStorageKey.cancelReason)

      router.push({ name: 'cancel_done' })
    } catch (error) {
      // https://firebase.google.com/docs/reference/js/auth#autherrorcodes
      switch (error.code) {
      case 'auth/user-not-found':
      case 'auth/invalid-email':
      case 'auth/wrong-password':
        commit('setTelop', { show: true, msg: 'メールアドレスまたはパスワードが誤っています', type: 'warning' }, { root: true })
        commit('setProcessing', false, { root: true })
        break
      default:
        sessionStorage.removeItem(sessionStorageKey.cancelUID)
        sessionStorage.removeItem(sessionStorageKey.cancelReason)
        commit('setTelop', { show: true, msg: '退会時に問題が発生しました\nしばらく時間をおいてお試しください', type: 'error' }, { root: true })
        router.replace({ name: 'error' })
      }
    }
  },
  /**
   * 認証情報変更時にユーザ情報を受け取りパスワード認証関連の処理判定を行い後続処理をどうするかを指示する
   * @param {Object} currentUser ログイン済みユーザ
   * @return {Object} 後続処理の制御情報 { continueProcessed: <処理を続行するか>, isTransition: <ページ遷移するかどうか>, viewName?: <遷移先画面名>, query?: <遷移先に引き継ぐパラメータ> }
   */
  changePasswordState: async ({ commit, getters, rootGetters, dispatch }, currentUser) => {
    const transitionPath = rootGetters.redirectPath
    const isPasswordProvider = isPasswordProviderUser(currentUser)
    const emailVerified = isVerifiedEmailUser(currentUser)
    const isSignupWithPassword = sessionStorage.getItem(sessionStorageKey.isSignupWithPassword)
    const isResetPassword = sessionStorage.getItem(sessionStorageKey.isResetPassword)
    const isDeleteAccountWithPassword = sessionStorage.getItem(sessionStorageKey.isDeleteAccountWithPassword)
    const queryParameter = rootGetters.queryParameter
    const query = {}
    queryParameter.substr(1).split('&').forEach(params => {
      const param = params.split('=')
      if (param[0]) query[param[0]] = decodeURIComponent(param[1])
    })
    const isRedirectAuthMail = transitionPath && transitionPath.includes('/mail/action')
    const isVerifyEmailAction = isRedirectAuthMail && query.mode === 'verifyEmail'

    // 登録中 or アカウント削除中 or パスワードリセット中は何も処理せず終了する
    if (isSignupWithPassword || isDeleteAccountWithPassword || isResetPassword) {
      [sessionStorageKey.isSignupWithPassword,
        sessionStorageKey.isDeleteAccountWithPassword,
        sessionStorageKey.isResetPassword].forEach(key => sessionStorage.removeItem(key))
      return { continueProcessed: false, isTransition: false }
    }

    commit('setEmailState', { emailVerified, isPasswordProvider })

    // パスワード認証ユーザでメール未認証の場合(メール認証処理の場合は除く)
    if (!isVerifyEmailAction && isPasswordProvider && !emailVerified) {
      const { name } = await dispatch('reSendEmailVerification', currentUser)
      return { continueProcessed: false, isTransition: true, viewName: name, query: query }
    }

    // 該当しない場合はもともとの処理を続行させる
    return { continueProcessed: true, isTransition: false }
  }
}

/**
 * @param {Object} user ログイン済みユーザ
 * @return {Boolean} パスワード認証によるユーザかどうか
 */
const isPasswordProviderUser = user => {
  const providerDatas = user ? user.providerData : []
  const providerId = providerDatas.length > 0 ? providerDatas[0].providerId : ''
  return providerId === 'password'
}

/**
 * @param {Object} user ログイン済みユーザ
 * @return {Boolean} メール認証済みユーザかどうか
 */
const isVerifiedEmailUser = user => user && user.emailVerified

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
