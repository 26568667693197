import { firebase, provider } from '@/firebase'
import router from '@/router'

// authで利用するセッションストレージのkey名
const sessionStorageKey = {
  newRID: 'newRID',
  isSignup: 'isSignup',
  isLoggedout: 'isLoggedout',
  isManualLoggedin: 'isManualLoggedin',
  cancelUID: 'cancelUID',
  cancelReason: 'cancelReason',
  isLINEAuthProcessing: 'lineAuthProcessing',
  isLineSignout: 'isLineSignout'
}

// LINE認証時に利用するローカルストレージのkey名
// 一部の端末でLINEを経由した際にセッションが消えてしまっている事象がありその対策でlocalStorageに保存している
const lineLocalStorageKey = {
  newRID: 'newRID',
  isSignup: 'isSignup',
  cancelUID: 'cancelUID',
  cancelReason: 'cancelReason',
  isLINEAuthProcessing: 'lineAuthProcessing'
}

const getDefaultState = () => {
  return {
    uid: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} ユーザーID
   */
  uid: state => state.uid
}

const mutations = {
  /**
   * ユーザーIDをstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   */
  setUID: (state, uid) => {
    state.uid = uid
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
    * auth情報の更新があった際の処理
    */
  async onAuth ({ commit, getters, rootGetters, dispatch }) {
    firebase.auth().onAuthStateChanged(async auth => {
      // パスワード認証関連はauthPasswordに任せ、場合によってはページ遷移 or 何もせずに処理を終わらせる
      const processingControl = await dispatch('authPassword/changePasswordState', auth, { root: true })
      if (!processingControl.continueProcessed && processingControl.isTransition) {
        commit('setAuthProcessing', false, { root: true })
        router.replace({ name: processingControl.viewName, query: processingControl.query })
        return
      } else if (!processingControl.continueProcessed) {
        commit('setAuthProcessing', false, { root: true })
        return
      } else {
        // 後続処理を続ける
      }

      const transitionPath = rootGetters.redirectPath
      const isLINEAuthProcessing = sessionStorage.getItem(sessionStorageKey.isLINEAuthProcessing) ? sessionStorage.getItem(sessionStorageKey.isLINEAuthProcessing) : localStorage.getItem(lineLocalStorageKey.isLINEAuthProcessing)
      const isLineSignout = sessionStorage.getItem(sessionStorageKey.isLineSignout)
      const isRedirectAuthLine = isLINEAuthProcessing && transitionPath && transitionPath.includes('/auth/line')

      // LINE認証情報を削除するためのsignoutの場合は該当のセッションキーを削除して何もしない
      if (sessionStorage.getItem(sessionStorageKey.isLoggedout) && isLineSignout) {
        sessionStorage.removeItem(sessionStorageKey.isLoggedout)
        sessionStorage.removeItem(sessionStorageKey.isLineSignout)
        return
      }

      if (isRedirectAuthLine) {
        if (auth) {
          // 認証情報が残っている場合は削除するためにログアウトを実行する
          sessionStorage.setItem(sessionStorageKey.isLineSignout, true)
          await dispatch('signout', { root: true })
        }
        const queryParameter = rootGetters.queryParameter

        const query = {}
        queryParameter.substr(1).split('&').forEach(params => {
          const param = params.split('=')
          if (param[0]) query[param[0]] = decodeURIComponent(param[1])
        })

        commit('setAuthProcessing', false, { root: true })
        router.replace({ path: transitionPath, query: query })
        return
      }

      commit('setAuthProcessing', true, { root: true })

      // アカウント作成で必要な情報の取得
      // アカウント作成の場合は、Signup.vueで作成した診断結果のridがセッションに保存されている
      const newRID = sessionStorage.getItem(sessionStorageKey.newRID) ? sessionStorage.getItem(sessionStorageKey.newRID) : localStorage.getItem(lineLocalStorageKey.newRID)
      const isSignup = sessionStorage.getItem(sessionStorageKey.isSignup) ? sessionStorage.getItem(sessionStorageKey.isSignup) : localStorage.getItem(lineLocalStorageKey.isSignup)
      const isLoggedin = !newRID
      const isLoggedout = sessionStorage.getItem(sessionStorageKey.isLoggedout)
      const isManualLoggedin = sessionStorage.getItem(sessionStorageKey.isManualLoggedin)
      const emailVerified = rootGetters['authPassword/emailVerified']
      const isPasswordProvider = rootGetters['authPassword/isPasswordProvider']

      // 退会処理かどうかの情報取得
      // 退会処理の場合、Cancel.vueで作成した退会処理情報が保存されている
      const cancelUID = sessionStorage.getItem(sessionStorageKey.cancelUID) ? sessionStorage.getItem(sessionStorageKey.cancelUID) : localStorage.getItem(lineLocalStorageKey.cancelUID)

      // auth情報をセット
      commit('setUID', auth ? auth.uid : null)

      // 並列処理用（必ず利用するもの）
      // 診断内容 / 脳タイプごとの割合
      const promises = [dispatch('checks/getChecks', null, { root: true })];
      ['お父さん脳', 'お母さん脳', '少年脳', '少女脳'].forEach(innate => {
        ['お父さん脳', 'お母さん脳', '子供脳'].forEach(acquire => {
          promises.push(dispatch('rate/getRate', { innate: innate, acquire: acquire }, { root: true }))
        })
      })

      if (cancelUID) {
        // 退会処理を行ったアカウントと再認証アカウントが同じかどうか
        const isSameAccount = auth ? cancelUID === auth.uid : false

        if (isSameAccount) {
          const cancelReason = sessionStorage.getItem(sessionStorageKey.cancelReason) ? sessionStorage.getItem(sessionStorageKey.cancelReason) : localStorage.getItem(lineLocalStorageKey.cancelReason)
          const idTokenResult = await auth.getIdTokenResult()
          const isLINEAuth = idTokenResult && idTokenResult.claims && idTokenResult.claims.provider === 'LINE'

          // 退会処理
          // ユーザー情報の削除と退会情報の作成
          promises.push(dispatch('users/deleteUser', auth.uid, { root: true }))
          promises.push(dispatch('cancels/addCancel', { uid: auth.uid, reason: cancelReason }, { root: true }))

          if (isLINEAuth) {
            promises.push(dispatch('functions/unlinkLine', null, { root: true }))
          }

          await Promise.all(promises)

          // Auth情報の削除
          await dispatch('deleteAuth')

          // CancelDoneページに遷移
          commit('setRedirectURL', '/cancel/done', { root: true })
        } else {
          // 退会処理と再認証アカウントが異なるので、再ログインを促す
          await dispatch('signout')

          const msg = 'アカウントの存在するSNSを選んでください。'
          commit('setTelop', { show: true, msg: msg, type: 'warning' }, { root: true })

          // ログインページに遷移
          commit('setRedirectURL', '/', { root: true })
        }
      } else if (auth) {
        // ユーザー情報、全診断結果情報、友達情報の取得
        promises.push(dispatch('users/getUser', auth.uid, { root: true }))
        promises.push(dispatch('results/getResults', auth.uid, { root: true }))
        promises.push(dispatch('follows/getFollows', auth.uid, { root: true }))
        await Promise.all(promises)

        // ユーザー情報と全診断結果の取得
        const user = rootGetters['users/user'](auth.uid)
        const results = rootGetters['results/results'](auth.uid)

        // 状況に応じた分岐
        if (isLoggedin) {
          // 通常は初回アクセスのページ
          let route = rootGetters.redirectPath

          // 状況に応じて遷移先を決定
          if (results.length === 0) {
            // 診断結果がない
            route = '/noexists'
            dispatch('signout', { root: true })
          } else if (!user && (!isPasswordProvider || isPasswordProvider && emailVerified)) {
            // ユーザー情報がない場合(パスワード認証でメール未認証を除く)
            route = '/signup/detail'
          } else if (isManualLoggedin) {
            // ログインボタン押下の場合
            route = '/home'
          }

          // 遷移先の格納
          commit('setRedirectURL', route, { root: true })
        } else {
          // 診断結果とユーザー情報の有無に応じて処理
          if (user) {
            // 今回の診断結果を削除
            await dispatch('results/deleteResult', newRID, { root: true })

            commit('setTelop', { show: true, msg: 'すでにアカウントが存在しています', type: 'warning' }, { root: true })
            commit('setRedirectURL', '/home', { root: true })
          } else {
            // 診断結果にユーザーIDを紐づける
            await dispatch('results/updateResult', {
              rid: newRID,
              params: {
                uid: auth.uid,
                updatedAt: new Date()
              }
            }, { root: true })
            commit('setRedirectURL', '/signup/detail', { root: true })
          }
        }
      } else {
        await Promise.all(promises)

        // auth情報がいないけど未認証じゃない場合の対応
        if (isManualLoggedin) {
          if (isSignup) {
          // サインナップなのにauth情報がない場合（同一メールアドレスで登録済み）
            commit('setTelop', { show: true, msg: 'すでにアカウントが存在しています。\n他のログイン方法をお試しください。', type: 'warning' }, { root: true })
            commit('setRedirectURL', '/', { root: true })

            // 今回の診断結果を削除
            await dispatch('results/deleteResult', newRID, { root: true })
          } else {
            // 自動ログインなのにauth情報がない場合
            commit('setRedirectURL', '/noexists', { root: true })
          }
        } else if (isLoggedout) {
          // サインアウトの場合は必ずTopへ遷移
          commit('setRedirectURL', '/', { root: true })
        }
      }

      // 初回アクセス場所にリダイレクトさせ、routerを反応させる
      const redirectPath = rootGetters.redirectPath
      const queryParameter = rootGetters.queryParameter

      // 元々あったパラメータを消さないように再セット
      const query = {}
      queryParameter.substr(1).split('&').forEach(params => {
        const param = params.split('=')
        if (param[0]) query[param[0]] = decodeURIComponent(param[1])
      })

      // 認証処理呼び出し時にセットされるものを削除
      Object.values(sessionStorageKey).forEach(key => sessionStorage.removeItem(key))
      Object.values(lineLocalStorageKey).forEach(key => localStorage.removeItem(key))

      commit('setAuthProcessing', false, { root: true })
      router.replace({ path: redirectPath, query: query })
    })
  },
  /**
   * サインイン
   * @param {String} name プロバイダー名
   */
  signin: async ({ commit }, name) => {
    try {
      sessionStorage.setItem(sessionStorageKey.isManualLoggedin, true)
      await firebase.auth().signInWithRedirect(provider[name])
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * カスタムトークンを利用してsigninを行う
   * @param {String} token firebase認証用カスタムトークン
   */
  signInWithCustomToken: async ({ commit }, token) => {
    try {
      sessionStorage.setItem(sessionStorageKey.isManualLoggedin, true)
      await firebase.auth().signInWithCustomToken(token)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * サインアウト
   */
  signout: async ({ commit, dispatch }) => {
    try {
      sessionStorage.setItem('isLoggedout', true)
      await firebase.auth().signOut()

      // storeの不要な情報をリセットする
      dispatch('resetState', null, { root: true })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * Auth情報の削除
   * 注意：退会処理を行うには最近ログインしている必要がある
   */
  deleteAuth: async ({ commit }) => {
    try {
      await firebase.auth().currentUser.delete()
      commit('setUID', null)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
