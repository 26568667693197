import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 最新のお知らせ情報
    // { nid: <ドキュメントID>, message: <お知らせの内容>, startAt: <掲載開始日>, endAt: <掲載終了日時>,createdAt: <入稿日時>,updatedAt: <更新日時> }
    notification: {},
    // 取得済みかどうが
    hasGot: false
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} お知らせを取得済みであるか
   */
  hasGot: state => state.hasGot,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolean} 表示可能かどうか (開始日時が現在日時よりも過去かつ終了日時が現在日時よりも未来)
   */
  isDisplayed: state => nowDate => state.notification && state.notification.startAt && state.notification.endAt ?
    state.notification.startAt.toDate() <= nowDate && state.notification.endAt.toDate() >= nowDate : false,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} お知らせの内容
   */
  message: state => state.notification ? state.notification.message : ''
}

const mutations = {
  /**
   * お知らせ情報をstateにセットする
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} notification お知らせ情報
   */
  setNotification: (state, notification) => {
    state.notification = Object.assign({}, notification)
  },
  /**
   * 取得済みかどうかの状態を買える
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Boolean} hasGot 取得済みかどうか
   */
  setHasGot: (state, hasGot) => {
    state.hasGot = hasGot
  },
  /**
   * stateをリセットする
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * お知らせ情報の取得
   * 掲載終了日時が一番遅いものを取得する
   */
  getNotification: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('notification')
        .orderBy('endAt', 'desc')
        .limit(1)
        .get()
      const doc = snapshot.empty ? null : snapshot.docs[0]
      if (doc) {
        commit('setNotification', Object.assign({ nid: doc.id }, doc.data()))
      }
      commit('setHasGot', true)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
