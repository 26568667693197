import { firestore } from '@/firebase'
import router from '@/router'

// 性別を聞く際の質問
const SEX_QUESTION = '生まれた時の性別を教えてください'

// 相性率を求める際に含めない質問
const AFFINITIES_EXCLUDE_QUESTIONS = [
  '生まれた時の性別を教えてください',
  '産まれたときの利き手はどちらですか？左利きを矯正されても左利きとお答え下さい。',
  'ゆびを組んだとき、どちらの親指が上ですか？',
  'うでを組んだとき、どちらの腕が上ですか？'
]

const getDefaultState = () => {
  return {
    // 全ての質問一覧
    allQuestions: [],
    // 予約中の質問一覧
    bookingQuestions: [],
    // 診断の質問情報の一覧
    // { cid: { qid: {}, qid: {}, ... }, cid: { qid: {}, qid: {}, ... }, ... }
    questions: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid 診断内容のドキュメントID
   * @return {Object} 指定診断の質問情報の一覧
   */
  questions: state => cid => state.questions[cid] || null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 公開済み全ての質問情報の一覧
   */
  allQuestions: state => state.allQuestions,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object[]} 予約中の全ての質問情報の一覧
   */
  bookingQuestions: state => state.bookingQuestions,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} qid 質問ID
   * @return {Object} 質問情報（ない場合はnull）
   */
  question: state => qid => state.allQuestions.find(question => question.qid === qid) || null,
  /**
   * ドキュメントIDと表示順に並んだ設問IDを指定して表示順に並んだ設問一覧を返却する
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid ドキュメントID
   * @param {String[]} qids 表示順に並んだ設問ID
   * @return {Object[]} 指定したqidの順番で並び替えられた設問
   */
  questionsByDisplayOrder: state => (cid, qids) => {
    const questions = state.questions[cid] || null
    return questions ? qids.map(qid => questions[qid]) : []
  },
  /**
   * 相性率を計算する際に必要な質問のID一覧
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String[]} qidの一覧
   */
  affinityQids: state => cid => {
    const questions = state.questions[cid] || null
    return questions ? Object.keys(questions).filter(qid => !AFFINITIES_EXCLUDE_QUESTIONS.includes(questions[qid].question)) : []
  },
  /**
   * 性別の質問のqidを取得する
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} 性別の質問のqid
   */
  sexQid: state => cid => {
    const questions = state.questions[cid] || null
    return questions ? Object.values(questions).find(item => item.question === SEX_QUESTION).qid : ''
  }
}

const mutations = {
  /**
   * cidに紐づく全ての質問をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数
   * @param {String} payload.cid 診断内容のドキュメントID
   * @param {Object} payload.questions 質問情報の一覧
   */
  setQuestions: (state, payload) => {
    state.questions = Object.assign({}, state.questions, { [payload.cid]: payload.questions })
  },
  /**
   * 公開済みの全質問をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object[]} questions 質問情報の一覧
   */
  setAllQuestions: (state, questions) => {
    state.allQuestions = questions
  },
  /**
   * 予約中の全質問をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object[]} questions 質問情報の一覧
   */
  setBookingQuestions: (state, questions) => {
    state.bookingQuestions = questions
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 指定したcidに紐づく全質問の取得
   * @param {String} cid 診断内容のドキュメントID
   * @return {Object} 質問情報の一覧
   */
  getQuestions: async ({ commit }, cid) => {
    try {
      const snapshot = await firestore
        .collection('questions')
        .where('cid', '==', cid)
        .get()

      const questions = {}
      snapshot.forEach(doc => {
        questions[doc.id] = Object.assign(doc.data(), { qid: doc.id })
      })
      commit('setQuestions', { cid: cid, questions: questions })

      return questions
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 質問を最新から全件取得する
   * @return {void}
   */
  getAllQuestions: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('questions')
        .where('startAt', '<=', new Date())
        .orderBy('startAt', 'desc')
        .get()

      const questions = []
      snapshot.forEach(doc => {
        questions.push(Object.assign(doc.data(), { qid: doc.id }))
      })
      commit('setAllQuestions', questions)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 予約中の質問を全件取得する
   * @return {void}
   */
  getBookingQuestions: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('questions')
        .where('startAt', '>', new Date())
        .orderBy('startAt', 'desc')
        .get()

      const questions = []
      snapshot.forEach(doc => {
        questions.push(Object.assign(doc.data(), { qid: doc.id }))
      })
      commit('setBookingQuestions', questions)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
