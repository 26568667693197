import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 自身の回答情報を取得済みかどうか
    isGotOwnAnswers: false,
    // 自身の質問への回答結果
    // { qid: {}, ... }
    ownAnswers: {},
    // 全員の質問への回答結果
    // { qid: [], ... }
    allAnswers: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Boolea} 回答情報を取得済みかどうか
   */
  isGotAnswers: state => state.isGotOwnAnswers,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} qid 質問ID
   * @return {Object} 回答情報
   */
  answer: state => qid => state.ownAnswers[qid] || null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} qid 質問ID
   * @return {Object[]} 回答情報
   */
  allAnswers: state => qid => state.allAnswers[qid] || null
}

const mutations = {
  /**
   * 質問への回答情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Boolean} isGot 取得済みかどうか
   */
  setIsGotAnswers: (state, isGot) => {
    state.isGotOwnAnswers = isGot
  },
  /**
   * 質問への回答情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} qid 質問ID
   * @param {Object} answer 回答情報
   */
  setAnswer: (state, { qid, answer }) => {
    state.ownAnswers = Object.assign({}, state.ownAnswers, { [qid]: answer })
  },
  /**
   * 全員の質問への回答情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} qid 質問ID
   * @param {Object} answer 回答情報
   */
  pushAllAnswers: (state, { qid, answer }) => {
    state.allAnswers = {
      ...state.allAnswers,
      [qid]: [...(state.allAnswers[qid] || []), answer]
    }
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 質問の回答情報の格納
   * @param {Object} postParams ポストパラメータ
   * @return {void}
   */
  addQuestionAnswer: async ({ commit }, postParams) => {
    try {
      await firestore
        .collection('question_answers')
        .add(postParams)

      // 回答情報の格納
      commit('setAnswer', { qid: postParams.qid, answer: postParams })
      commit('pushAllAnswers', { qid: postParams.qid, answer: postParams })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 質問に関する回答情報の取得
   * @param {String} uid ユーザーID
   * @return {void}
   */
  getQuestionAnswers: async ({ commit }, uid) => {
    try {
      const snapshot = await firestore
        .collection('question_answers')
        .where('uid', '==', uid)
        .get()

      // 回答情報の格納
      snapshot.forEach(doc => {
        const data = doc.data()
        commit('setAnswer', { qid: data.qid, answer: data })
      })
      commit('setIsGotAnswers', true)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 質問IDに基づく回答情報の取得
   * @param {String} qid 質問ID
   * @return {void}
   */
  getQuestionAnswersByQID: async ({ commit }, qid) => {
    try {
      const snapshot = await firestore
        .collection('question_answers')
        .where('qid', '==', qid)
        .get()

      // 回答情報の格納
      snapshot.forEach(doc => {
        const data = doc.data()
        commit('pushAllAnswers', { qid: data.qid, answer: data })
      })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
