import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 診断結果のオブジェクト一覧
    // { cid: {}, cid: {}, ... }
    checks: {},
    // 脳タイプ診断のドキュメントID
    brainCheckCID: null
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid ドキュメントID
   * @return {Object} 指定の診断内容のオブジェクト
   */
  check: state => cid => state.checks[cid] ? state.checks[cid] : null,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 全診断内容のオブジェクト
   */
  checks: state => state.checks,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 脳タイプ診断のドキュメントID
   */
  brainCheckCID: state => state.brainCheckCID
}

const mutations = {
  /**
   * 診断結果の格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数
   * @param {String} payload.cid ドキュメントID
   * @param {Obcjet} payload.check 格納する診断結果のドキュメント
   */
  setCheck: (state, payload) => {
    state.checks = Object.assign({}, state.checks, { [payload.cid]: payload.check })
  },
  /**
   * 診断結果の格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid ドキュメントID
   */
  setBrainCheckCID: (state, cid) => {
    state.brainCheckCID = cid
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 診断内容の取得
   */
  getChecks: async ({ commit }) => {
    try {
      const snapshot = await firestore
        .collection('checks')
        .get()

      snapshot.forEach(doc => {
        if (doc.data().name === '脳タイプ診断') commit('setBrainCheckCID', doc.id)
        commit('setCheck', { cid: doc.id, check: doc.data() })
      })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
