import { firestore } from '@/firebase'
import router from '@/router'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * 退会情報の登録
   * @param {Object} payload 引数
   * @param {String} payload.uid 退会するユーザーID
   * @param {String} payload.reason 退会理由
   */
  addCancel: async ({ commit }, payload) => {
    try {
      await firestore
        .collection('cancels')
        .doc(payload.uid)
        .set({
          reason: payload.reason,
          createdAt: new Date()
        })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
