import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // 脳タイプ別の診断結果
    // { cid: [], ... }
    brains: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid ドキュメントID
   * @param {String} innate 先天的脳タイプ名
   * @param {String} accuire 後天的脳タイプ名
   * @return {Object} 指定診断に紐づく脳タイプ別の診断結果
   */
  brains: state => (cid, innate, accuire, sex) => state.brains[cid] ? state.brains[cid].filter(brain => brain.innate === innate && brain.accuire === accuire && brain.sex === sex) : null
}

const mutations = {
  /**
   * 脳タイプ別診断結果の格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid 診断のドキュメントID
   * @param {Object[]} brains 脳タイプ別診断結果
   */
  setBrainss: (state, { cid, brains }) => {
    state.brains = Object.assign({}, state.brains, { [cid]: brains })
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 指定診断に紐づく脳タイプ別診断結果の取得
   * @param {String} cid 診断内容のドキュメントID
   */
  getBrains: async ({ commit }, cid) => {
    try {
      const snapshot = await firestore
        .collection('checks')
        .doc(cid)
        .collection('brains')
        .orderBy('number')
        .get()

      const brains = snapshot.docs.map(doc => Object.assign(doc.data(), { bid: doc.id }))
      commit('setBrainss', { cid: cid, brains: brains })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
