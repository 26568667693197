import { createApp } from 'vue'
import VueClipboard from 'vue-clipboard2'
import VueGtm from '@gtm-support/vue-gtm'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import { plugin as vueMetaPlugin } from 'vue-meta'

import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'

const app = createApp(App)
const vueMetaManager = createMetaManager(false)
app.config.devtools = process.env.NODE_ENV !== 'production'
app.config.productionTip = false

/**
 * @see https://github.com/Inndy/vue-clipboard2
 */
app.use(VueClipboard)

/**
 * setting of gtm
 * @see https://github.com/gtm-support/vue-gtm/tree/vue2-gtm
 */
app.use(VueGtm, { id: 'GTM-T33GNZW' })

/**
 * setting of vue-meta
 * @see https://github.com/nuxt/vue-meta
 */
app.use(vueMetaManager)
app.use(metaPlugin)
app.use(vueMetaPlugin)

app.use(router)
app.use(store)
app.use(vuetify)

app.mount('#app')
