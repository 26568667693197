import { firestore } from '@/firebase'
import router from '@/router'

const getDefaultState = () => {
  return {
    // マズローの診断結果
    // { cid: [], ... }
    maslows: {}
  }
}

const state = getDefaultState()

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid ドキュメントID
   * @return {Object} 指定診断に紐づくマズローの診断結果
   */
  maslows: state => cid => state.maslows[cid] ? state.maslows[cid] : null
}

const mutations = {
  /**
   * マズロー情報の格納
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} cid 診断のドキュメントID
   * @param {Object[]} maslows マズロー情報の一覧
   */
  setMaslows: (state, { cid, maslows }) => {
    state.maslows = Object.assign({}, state.maslows, { [cid]: maslows })
  },
  /**
   * stateのリセットを行う
   *
   * @param {Object} state 暗黙的に受け取るstate
   */
  resetState: state => {
    state = Object.assign(state, getDefaultState())
  }
}

const actions = {
  /**
   * 指定診断に紐づくマズロー情報の取得
   * @param {String} cid 診断内容のドキュメントID
   */
  getMaslows: async ({ commit }, cid) => {
    try {
      const snapshot = await firestore
        .collection('checks')
        .doc(cid)
        .collection('maslows')
        .orderBy('step')
        .get()

      const maslows = snapshot.docs.map(doc => Object.assign(doc.data(), { mlid: doc.id }))
      commit('setMaslows', { cid: cid, maslows: maslows })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
